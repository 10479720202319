import getDisplayTimestamp from './getDisplayTimestamp';
import getDisplayTxnStatus from './getDisplayTxnStatus';

export default (transactions) =>
  transactions?.map((txn) => {
    /**
     * This function adds a "displayTimestamp" field to all txns. There is some specific logic to what time to display to customer and how to order
     * it based on those times. Rather than having it in a component, it is probably better suited here.
     */
    const txnCopy = txn;
    const displayStatus = getDisplayTxnStatus({
      transactionPaymentStatusApi: txnCopy.paymentStatus,
      transactionStatusApi: txnCopy.status,
      transactionTypeApi: txnCopy.type,
    });

    const displayTimestamp = getDisplayTimestamp({
      processedTimestamp: txnCopy.processedTimestamp,
      dueTimestamp: txnCopy.dueTimestamp,
      displayStatus,
    });

    txnCopy.displayTimestamp = displayTimestamp;
    txnCopy.displayStatus = displayStatus;
    return txnCopy;
  });
