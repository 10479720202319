import { palette, typography } from '../../global';

export default {
  styleOverrides: {
    root: {
      '&.Mui-selected': {
        color: palette.secondary.darkest,
      },
      '&.Mui-selected span': {
        color: palette.secondary.main,
        fontSize: typography.h3.fontSize,
        lineHeight: '0.3',
      },
    },
  },
};
