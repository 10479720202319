import { typography } from '../../global';

export default {
  styleOverrides: {
    root: {
      width: '100%',
      fontFamily: typography.h1.fontFamily,
    },
  },
};
