const PARTNER_CODES = {
  PODS: 'PODS',
  OFFERPAD: 'OFFERPAD',
  HOME_DEPOT: 'HOME_DEPOT',
  CUBESMART: 'CUBESMART',
  MOVE_EASY: '631b3df0-8450-4121-ab2b-316e221f31ad',
  REDFIN: 'REDFIN',
  BELLHOP_REFERRALS: 'Bellhop_Referrals',
};

const cobrandedPartners = [PARTNER_CODES.PODS];
const modifyPartners = []; // Off for all partners
const notesPartners = [PARTNER_CODES.PODS];
const receiptPartners = [PARTNER_CODES.PODS];
const reschedulePartners = [PARTNER_CODES.PODS];
// Everyone except PODS is a "legacy" partner that is being migrated to affiliate, this is to cover the case until all orders use affiliate code
const showTransactionPartners = [
  PARTNER_CODES.PODS,
  PARTNER_CODES.HOME_DEPOT,
  PARTNER_CODES.CUBESMART,
  PARTNER_CODES.MOVE_EASY,
  PARTNER_CODES.REDFIN,
  PARTNER_CODES.BELLHOP_REFERRALS,
];

export default function getPartner(reservation) {
  const { partnerCode } = reservation;

  // No partner code - return null for valid checks on page usage
  if (partnerCode === '') return null;

  // Unique partner configurations set within this object as needed
  const partnerConfig = {
    code: partnerCode,
    cobrand: cobrandedPartners.includes(partnerCode),
    modify: modifyPartners.includes(partnerCode),
    notes: notesPartners.includes(partnerCode),
    receipt: receiptPartners.includes(partnerCode),
    reschedule: reschedulePartners.includes(partnerCode),
    showTransactions: showTransactionPartners.includes(partnerCode),
  };

  return partnerConfig;
}
