/**
 * I got below values fromt the 'graphql-api' repository:
 * https://github.com/bellhops/graphql-api/blob/master/src/schema.js#L802
 */
export const TRANSACTION_STATUS_API = {
  CANCELED: 'canceled',
  COMPLETED: 'completed',
  FAILED: 'failed',
  ON_HOLD: 'on hold',
  OPEN: 'open',
};

export const TRANSACTIONS_STATUS_UI = {
  CANCELED: 'canceled',
  DECLINED: 'declined',
  DUE: 'due',
  ISSUED: 'issued',
  PAID: 'paid',
  PENDING: 'pending',
};

export const TRANSACTION_PAYMENT_STATUS_API = {
  AUTHORIZED: 'authorized',
  CAPTURED: 'captured',
  PARTIALLY_CAPTURED: 'partially captured',
  PARTIALLY_REFUNDED: 'partially refunded',
  REFUNDED: 'refunded',
  UNCAPTURED: 'uncaptured',
};

export const TRANSACTION_TYPE = {
  NORMAL: 'normal',
  REFUND: 'refund',
};

/**
 * I got below values from the booking-api repository:
 * https://github.com/bellhops/booking-api/blob/7e69a2a6cf20e9ea4c06a818cc91a0fc6d615b1a/booking/herokuconnect/transaction.go#L93
 */
export const TRANSACTION_SOURCE_API = {
  SETTLEMENT: 'settlement',
  ADDITIONAL_PAYMENT_TIP: 'additional payment - tip',
  DEPOSIT: 'deposit',
  FINAL_PAYMENT: 'final payment',
  OVERPAID_REFUND: 'overpaid - refund',
  UNAUTHORIZED_PAYMENT: 'unauthorized payment',
};
