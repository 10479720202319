import getServiceJobs from './getServiceJobs';
import prepJobSlots from './prepJobSlots';
import getPermissions from './getPermissions';
import prepTransactions from './prepTransactions';
import getPartner from './getPartner';

export default function prepReservation(reservation) {
  const retReservation = JSON.parse(JSON.stringify(reservation));
  if (!retReservation.jobs) return retReservation;
  retReservation.originalJobs = [...retReservation.jobs];
  retReservation.originalJobs.sort((a, b) => {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
  });
  retReservation.jobs = getServiceJobs(reservation);
  retReservation.jobs = prepJobSlots(retReservation.jobs);
  retReservation.jobs.sort((a, b) => {
    if (a.start < b.start) return -1;
    if (a.start > b.start) return 1;
    return 0;
  });
  retReservation.transactions = prepTransactions(reservation.transactions);
  retReservation.partner = getPartner(reservation);
  retReservation.permissions = getPermissions(reservation, retReservation.partner, retReservation.jobs);
  if (reservation?.paymentMethod) {
    const { __typename, ...restPaymentMethod } = reservation.paymentMethod; // want to store the API __typename as type in paymentMethod object for ease of use on pages/components
    retReservation.paymentMethod = {
      ...restPaymentMethod,
      type: __typename,
    };
  }
  retReservation.setReferralSkipped = reservation.setReferralSkipped;
  return retReservation;
}
