import { getSession } from 'next-auth/client';
import fetchAPI from '../fetchAPI';
import { serviceQuotesFragment, paymentMethodFragment } from '../fragments';

export default async () => {
  const session = await getSession();
  if (session?.user?.getReservationToken?.jwt) {
    const resquery = `
    query getReservation {
      reservation {
        orderCode
        status
        createdDate
        longDistance
        id
        notes
        partnerCode
        customer {
          firstName
          lastName
          email
          phone
          referral
        }
        ${paymentMethodFragment}
        jobs {
          id
          start
          startTimezone
          end
          endTimezone
          duration
          completed
          customerReviewed
          payoutStrategy
          pricing {
            currency
            amount
          }
          guaranteed
          service {
            id
            name
            description
            long_description
            short_description
            image_url
          }
          slots {
            id
            user {
              id
              firstName
              lastName
              photo {
                url
              }
            }
            skill
            customerReviewed
          }
          locations {
            id
            address {
              line1
              line2
              city
              region
              country
              postalCode
            }
            marketCode
            coordinates {
              lat
              lng
            }
            timezone
          }
          marketplaceMatchStatus
          marketplaceMatchExpiry
          marketplaceSupplyType
        }
        locations {
          id
          address {
            line1
            line2
            city
            region
            country
            postalCode
          }
          marketCode
        }
        orderItems {
          code
          product {
            code
          }
          source
          price {
            currency
            amount
          }
        }
        totals {
          balance {
            amount
            currency
          }
          subtotal {
            amount
            currency
          }
          total {
            amount
            currency
          }
        }
        promoCodes {
          source
        }
        transactions {
          dueTimestamp
          paymentStatus
          processedTimestamp
          source
          status
          type
          price {
            amount
            currency
          }
          retainedPrice {
            amount
            currency
          }
          ${paymentMethodFragment}
        }
        paymentMethod {
          ... on PaymentMethodAffirm {
            amount
            currency
          }
          ... on PaymentMethodCard {
            name
            lastFour
            expirationMonth
            expirationYear
            brand
          }
          __typename,
        }
        fees {
          id
          pricingStrategy
          pricing {
            currency
            amount
          }
          feeName
          feeID
          feeDescription
        }
        products {
          ${serviceQuotesFragment}
        }
      }
    }`;

    const { reservation } = await fetchAPI({
      query: resquery,
      variables: {},
      headers: {
        Authorization: `Bearer ${session.user.getReservationToken.jwt}`,
      },
    });

    return { reservation };
  }
  return { reservation: null };
};
