import { TRANSACTIONS_STATUS_UI } from '../../../constants/transactions';

export default ({ processedTimestamp, dueTimestamp, displayStatus }) => {
  switch (displayStatus) {
    case TRANSACTIONS_STATUS_UI.DECLINED:
      return processedTimestamp || dueTimestamp;
    case TRANSACTIONS_STATUS_UI.ISSUED:
      return processedTimestamp || dueTimestamp;
    case TRANSACTIONS_STATUS_UI.PAID:
      return processedTimestamp || dueTimestamp;
    case TRANSACTIONS_STATUS_UI.PENDING:
      return processedTimestamp || dueTimestamp;
    case TRANSACTIONS_STATUS_UI.DUE:
      return dueTimestamp;
    default:
      return processedTimestamp || dueTimestamp;
  }
};
