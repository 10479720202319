import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Error from '../pages/500';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  render() {
    const { hasError, errorInfo, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      // Dev to Prod, render prettified eror
      if (process.env.NEXT_PUBLIC_ENVIRONMENT !== 'local') {
        return <Error />;
      }
      // Locally, render logs of errors
      return (
        <div>
          <h2>An Error Occured. Check Logs.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {error && error.toString()}
            <br />
            {errorInfo?.componentStack}
          </details>
        </div>
      );
    }
    // Normally, render children
    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
