import { PRICING_STRATEGY } from '../../constants/pricing';

const HHG_LEAD = 'Team Lead and Driver';
const LEAD = 'Team Lead';
const HHG_PRO = 'Pro and Driver';
const TRUCK = 'Truck and Driver';
const PRO = 'Pro';
const RECRUIT = 'Recruit';

const isDriver = (skill) => skill.includes('TRANSIT');
const isLead = (skill) => skill.includes('CAPTAIN') || skill.includes('LEAD');
const isStandardPro = (skill) =>
  (skill.includes(PRICING_STRATEGY.HOURLY) ||
    skill.includes('LOADING') ||
    skill.includes('UNLOADING') ||
    skill.includes('PACKING')) &&
  !(skill.includes('CAPTAIN') || skill.includes('LEAD'));
const isRecruit = (skill) => skill.includes('RECRUIT');

const getSlotTitle = (slot) => {
  if (slot.jobSlotSkills.some(isDriver) && slot.jobSlotSkills.some(isLead)) return HHG_LEAD;
  if (slot.jobSlotSkills.some(isLead)) return LEAD;
  if (slot.jobSlotSkills.some(isDriver) && slot.jobSlotSkills.some(isStandardPro)) return HHG_PRO;
  if (slot.jobSlotSkills.some(isDriver)) return TRUCK;
  if (slot.jobSlotSkills.some(isRecruit)) return RECRUIT;
  if (slot.jobSlotSkills.some(isStandardPro)) return PRO;
  return 'Unknown'; // TODO: Don't want to leave this, default to Pro - would like for now to see if some logic missed above
};

export default function prepJobSlots(jobs) {
  return (
    jobs?.map((job) => {
      let newSlots = job.slots?.reduce((acc, slot) => {
        if (slot.user) {
          if (acc[slot.user.id]) {
            acc[slot.user.id].jobSlotIDs.push(slot.id);
            acc[slot.user.id].jobSlotSkills.push(slot.skill);
          } else {
            // Remove '_' from name fields and then replace any multi-space occurences with a single space
            const prettyFirstName = slot.user.firstName.replace(/_/g, ' ').replace(/\s+/g, ' ');
            const prettyLastName = slot.user.lastName.replace(/_/g, ' ').replace(/\s+/g, ' ');
            acc[slot.user.id] = {
              user: {
                ...slot.user,
                firstName: prettyFirstName,
                lastName: prettyLastName,
              },
              jobSlotIDs: [slot.id],
              jobSlotSkills: [slot.skill],
            };
          }
        } else {
          acc[slot.id] = {
            user: null,
            jobSlotIDs: [slot.id],
            jobSlotSkills: [slot.skill],
          };
        }
        return acc;
      }, []);

      newSlots = Object.keys(newSlots).map((index) => newSlots[index]);

      const hhgLead = [...newSlots.filter((slot) => getSlotTitle(slot) === HHG_LEAD)];
      const lead = [...newSlots.filter((slot) => getSlotTitle(slot) === LEAD)];
      const trucks = [...newSlots.filter((slot) => getSlotTitle(slot) === TRUCK)];
      const hhgPros = [...newSlots.filter((slot) => getSlotTitle(slot) === HHG_PRO)];
      const pros = [...newSlots.filter((slot) => getSlotTitle(slot) === PRO)];
      const recruits = [...newSlots.filter((slot) => getSlotTitle(slot) === RECRUIT)];

      const retSlots = {
        ...(hhgLead.length > 0 && { hhgLead: { title: HHG_LEAD, workers: hhgLead } }),
        ...(lead.length > 0 && { lead: { title: LEAD, workers: lead } }),
        ...(trucks.length > 0 && { trucks: { title: TRUCK, workers: trucks } }),
        ...(hhgPros.length > 0 && { hhgPros: { title: HHG_PRO, workers: hhgPros } }),
        ...(pros.length > 0 && { pros: { title: PRO, workers: pros } }),
        ...(recruits.length > 0 && { recruits: { title: RECRUIT, workers: recruits } }),
      };

      return {
        ...job,
        slots: retSlots,
      };
    }) ?? []
  );
}
