export default async ({ query, variables, headers }) => {
  const res = await fetch(`https://${process.env.NEXT_PUBLIC_API_HOSTNAME}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });

  const json = await res.json().catch((error) => {
    // ensure valid JSON response
    throw new Error(error[0]);
  });

  if (json.errors) {
    // errors returned from API
    json.errors.forEach((e) => {
      const rawError = {
        message: e.message,
        code: e.extensions?.code,
        status: e.extensions?.status,
        details: e.extensions?.details,
      };

      if (e.message === 'record not found' || e.message === 'invalid reservation') {
        // Known error response when credentials are incorrect
        console.log({
          correlationId: res.headers.get('correlation-id'),
          message: 'Incorrect Credentials',
          rawError,
        });
      } else {
        // Unknown errors
        console.error({
          correlationId: res.headers.get('correlation-id'),
          message: 'Unknown Error',
          rawError,
        });
      }
    });
    throw new Error(json.errors?.[0]?.message);
  }

  return json.data;
};
