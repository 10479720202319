/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import { node } from 'prop-types';
import { useRouter } from 'next/router';

const segmentMiddleware = ({ payload, next }) => {
  if (payload.obj.properties.path) {
    payload.obj.properties.path = window.location.pathname;
    payload.obj.properties.url = window.location.href;
    payload.obj.properties.userAgent = window.navigator.userAgent;
  }
  next(payload);
};

const SegmentPageListener = ({ children }) => {
  const router = useRouter();
  let clickid;
  useEffect(() => {
    if (window !== undefined) {
      const queryString = window?.location?.search;
      const urlParams = new URLSearchParams(queryString);
      clickid = urlParams.get('clickid');
    }
  }, []);

  useEffect(() => {
    if (clickid) {
      try {
        window?.localStorage?.setItem('clickid', clickid);
      } catch {
        // Do nothing on error - localStorage not accessible on client browser
      }
    }
  }, [clickid]);

  useEffect(() => {
    window?.analytics?.addSourceMiddleware(segmentMiddleware);
  }, []);

  useEffect(() => {
    if (window?.analytics?.page) {
      window.analytics.page({
        search: `${window.location.search ? `${window.location.search}&` : '?'}dp=dashboard${encodeURIComponent(
          router.pathname,
        )}`,
      });
    }
  }, [router?.pathname]);

  return children;
};

SegmentPageListener.propTypes = {
  children: node.isRequired,
};

export default SegmentPageListener;
