import React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import useSystem from '../../hooks/useSystem';

const Toasts = () => {
  const theme = useTheme();
  const system = useSystem();

  return (
    <Stack
      sx={{
        position: 'fixed',
        bottom: theme.spacing(3),
        maxWidth: { xs: '90%', md: '500px' },
        mb: { xs: theme.spacing(5), md: '0' },
        mx: 'auto',
        zIndex: 1,
        color: theme.palette.primary.main,
        left: 0,
        right: 0,
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
      spacing={1}
    >
      {system.userMessages.map((message) => (
        <Alert
          icon={<InfoIcon sx={{ color: 'white' }} />}
          sx={{
            width: 'fit-content',
            borderRadius: 80,
            backgroundColor: theme.palette.primary.main,
            color: 'white',
          }}
          onClose={() => {
            message.onClose(message.uuid);
          }}
          severity={message.severity}
        >
          {message.message}
        </Alert>
      ))}
    </Stack>
  );
};

export default Toasts;
