export default {
  fontFamily: ['Calibre', '-apple-system', 'system-ui', 'BlinkMacSystemFont', 'Arial', 'sans-serif'].join(','),
  h1: {
    fontFamily: 'CalibreMedium',
    fontSize: 60,
  },
  h2: {
    fontFamily: 'CalibreMedium',
    fontSize: 48,
  },
  h3: {
    fontFamily: 'CalibreMedium',
    fontSize: 36,
  },
  h4: {
    fontFamily: 'CalibreMedium',
    fontSize: 24,
  },
  h5: {
    fontFamily: 'CalibreMedium',
    fontSize: 18,
  },
  body1: {
    fontSize: 18,
    lineHeight: '1.6',
  },
  body2: {
    fontSize: 16,
    lineHeight: '1.6',
  },
  body3: {
    fontFamily: 'CalibreMedium',
    fontSize: 16,
    lineHeight: '1.6',
  },
  caption: {
    fontSize: '.75rem',
  },
  button: {
    fontFamily: 'CalibreMedium',
    fontSize: 18,
  },
};
