import React, { useState } from 'react';
import Head from 'next/head';
import { func, shape } from 'prop-types';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import { Provider as AuthProvider } from 'next-auth/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import createEmotionCache from '../utils/createEmotionCache';
import { SegmentPageListener } from '../modules/segment';
import theme from '../theme';
import ErrorBoundary from '../components/errorBoundary';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ReservationProvider from '../providers/ReservationProvider';
import Toasts from '../components/Toasts';
import SystemContextProvider from '../providers/SystemContext';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
const App = ({ Component, pageProps: { session, ...pageProps }, emotionCache }) => {
  const [queryClient] = useState(() => new QueryClient());

  const getLayout = Component.getLayout || ((page) => page);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <SegmentPageListener>
          <ErrorBoundary>
            <AuthProvider session={session} options={{ basePath: '/api/auth' }}>
              <QueryClientProvider client={queryClient}>
                <ReservationProvider>
                  <SystemContextProvider>
                    <Head>
                      <title>Bellhop | The Modern Alternative to Traditional Moving Companies</title>
                      <meta name='og:title' content='Bellhop | The Modern Alternative to Traditional Moving Companies' />
                      <meta name='robots' content={`'noindex', 'nofollow'`} />
                    </Head>
                    <Header />
                    {getLayout(<Component {...pageProps} />)}
                    <Toasts />
                    <Footer />
                  </SystemContextProvider>
                </ReservationProvider>
              </QueryClientProvider>
            </AuthProvider>
          </ErrorBoundary>
        </SegmentPageListener>
      </ThemeProvider>
    </CacheProvider>
  );
};

App.propTypes = {
  Component: func.isRequired,
  emotionCache: shape({}),
  pageProps: shape({}).isRequired,
};

App.defaultProps = {
  emotionCache: clientSideEmotionCache,
};

export default App;
