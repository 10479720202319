import { getSession } from 'next-auth/client';
import fetchAPI from '../fetchAPI';

const fetchOrderInvoice = async (orderCode, status) => {
  const session = await getSession();
  if (session?.user?.mock) {
    return { order: {} };
  }
  if (session?.user?.getReservationToken?.jwt) {
    const estimatedOrderQuery = `
    query getInvoice($orderId: String!, $invoiceStatus: InvoiceStatus!) {
      orderInvoice(orderID: $orderId, invoiceStatus: $invoiceStatus) {
        jobs {
          orderItemCode
          productCode
          monetaryValue
          status
          pricingStrategy
        }
      }
    }`;

    const data = await fetchAPI({
      query: estimatedOrderQuery,
      variables: {
        orderId: orderCode,
        invoiceStatus: status,
      },
      headers: {
        Authorization: `Bearer ${session.user.getReservationToken.jwt}`,
      },
    });

    return { invoice: data };
  }
  return { invoice: null };
};

export default fetchOrderInvoice;
