const paymentMethodFragment = `paymentMethod {
  ... on PaymentMethodPartner {
    token
  }
  ... on PaymentMethodCard {
    name
    lastFour
    expirationMonth
    expirationYear
    brand
    lastFour
  }
}`;

export default paymentMethodFragment;
