import React, { useMemo, useState } from 'react';
import { node } from 'prop-types';
import { useQuery } from 'react-query';
import { useSession } from 'next-auth/client';

import RESERVATION_STATUS from '../../constants/reservationStatus';
import ReservationContext from '../ReservationContext';
import fetchOrder from '../../api/queries/fetchOrder';
import fetchEstimatedOrder from '../../api/queries/fetchEstimatedOrder';
import fetchReservation from '../../api/queries/fetchReservation';
import prepReservation from '../../utils/prepReservation';
import fetchOrderInvoice from '../../api/queries/fetchOrderInvoice';

const ReservationProvider = ({ children }) => {
  const [session] = useSession();
  const { data, isLoading } = useQuery(['reservation', session?.user?.reservationID], fetchReservation, {
    staleTime: Infinity,
    useErrorBoundary: true,
  });
  const COMPLETED = data?.reservation?.status === RESERVATION_STATUS.COMPLETED;
  const CLOSED = data?.reservation?.status === RESERVATION_STATUS.CLOSED;
  const postMove = COMPLETED || CLOSED;

  const { data: orderData, isLoading: isOrderLoading } = useQuery(
    ['order', session?.user?.reservationID],
    () => fetchOrder(data?.reservation?.orderCode),
    {
      staleTime: Infinity,
      useErrorBoundary: true,
      enabled: Boolean(data?.reservation?.orderCode) && postMove,
    },
  );

  const { data: estimatedOrderData, isLoading: isEstimatedOrderLoading } = useQuery(
    ['estimatedOrder', session?.user?.reservationID],
    () => fetchEstimatedOrder(data?.reservation?.orderCode),
    {
      staleTime: Infinity,
      useErrorBoundary: true,
      enabled: Boolean(data?.reservation?.orderCode),
    },
  );
  const [referralSkipped, setReferralSkipped] = useState(false);

  const { data: scheduledOrderInvoice } = useQuery(
    ['scheduledOrderInvoice', session?.user?.reservationID],
    () => fetchOrderInvoice(data?.reservation?.orderCode, 'scheduled'),
    {
      staleTime: Infinity,
      useErrorBoundary: true,
      enabled: Boolean(data?.reservation?.orderCode) && false,
    },
  );

  const { data: completedOrderInvoice } = useQuery(
    ['completedOrderInvoice', session?.user?.reservationID],
    () => fetchOrderInvoice(data?.reservation?.orderCode, 'completed'),
    {
      staleTime: Infinity,
      useErrorBoundary: true,
      enabled: Boolean(data?.reservation?.orderCode) && postMove,
    },
  );

  const value = useMemo(() => {
    if (data && (orderData || estimatedOrderData)) {
      return prepReservation({
        ...data?.reservation,
        orderBreakdown: orderData?.order.order.breakdown,
        orderBalance: orderData?.order.order.balance,
        orderSubtotal: orderData?.order.order.subtotal,
        orderTotal: orderData?.order.order.total,
        orderIsMinimum: orderData?.order.order.isMinimum,
        orderEstimatedBalance: estimatedOrderData?.order.order.estimatedBalance,
        orderEstimatedSubtotal: estimatedOrderData?.order.order.estimatedSubtotal,
        orderEstimatedTotal: estimatedOrderData?.order.order.estimatedTotal,
        scheduledOrderInvoice: scheduledOrderInvoice?.invoice?.orderInvoice,
        completedOrderInvoice: completedOrderInvoice?.invoice?.orderInvoice,
        isLoading: isLoading || isOrderLoading || isEstimatedOrderLoading,
        referralSkipped,
        setReferralSkipped,
      });
    }
    return {
      isLoading: isLoading || isOrderLoading || isEstimatedOrderLoading,
      referralSkipped,
      setReferralSkipped,
    };
  }, [data, orderData, estimatedOrderData, scheduledOrderInvoice, completedOrderInvoice]);

  return <ReservationContext.Provider value={value}>{children}</ReservationContext.Provider>;
};

ReservationProvider.propTypes = {
  children: node.isRequired,
};

export default ReservationProvider;
