import React from 'react';
import { bool } from 'prop-types';

/**
 * The original Segment script has been modified from an 'async' script into a 'defer' script.
 * Also the execution time has been delayed until the 'load' event fires in the app, that is
 * when the app has finished loading and parsing all critical resources.
 *
 * Previously the Segment script was initialized while the critical app resources were still
 * loading, hurting the loading performance of the app, since Segment loads in 300 third-party
 * tools.
 */

const SegmentScript = ({ enabled }) =>
  enabled ? (
    <script
      type='text/javascript'
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.defer=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";document.body.appendChild(n);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
        window.addEventListener('load', (event) => {
          analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY}");
        });
        }}();`,
      }}
    />
  ) : null;

SegmentScript.propTypes = {
  enabled: bool,
};

SegmentScript.defaultProps = {
  enabled: false,
};

export default SegmentScript;
