import dayjs from 'dayjs';
import isWithinDates, { isMoveDay } from '../datetime/isWithinDates';
import RESERVATION_STATUS from '../../constants/reservationStatus';

export default function getPermissions(reservation, partnerConfig, serviceJobs) {
  const { paymentMethod, partnerCode, status, jobs, locations, longDistance } = reservation;

  const COMPLETED = status === RESERVATION_STATUS.COMPLETED;
  const CLOSED = status === RESERVATION_STATUS.CLOSED;

  const deltaEnabled = true;
  const postMove = COMPLETED || CLOSED;

  const isPastStartDate = jobs?.some((job) => dayjs().isAfter(dayjs.unix(job.start)));
  const allowTip = Boolean(paymentMethod);

  const showTransactions = !partnerCode || (partnerCode && partnerConfig?.showTransactions);
  const allowReschedule = false;
  // Leaving for re-enabling
  // (!partnerCode || partnerConfig.reschedule) &&
  // !longDistance &&
  // !(isWithinDates(jobs?.[0]?.start, 2, '[]') || COMPLETED || CLOSED || isPastStartDate);
  const allowUpdatePaymentMethod = false; // !partnerCode;
  const allowManageAccount = false; // !partnerCode;
  const allowCancel = false; // TEMPORARILY DISABLED
  // const allowCancel =
  //   !partnerCode && !longDistance && !(isWithinDates(jobs?.[0]?.start, 3, '[]') || COMPLETED || CLOSED || isPastStartDate);
  const allowModify = false;
  // Leaving for re-enabling
  // const allowModify = (!partnerCode || partnerConfig.modify) && !longDistance && !(COMPLETED || CLOSED || isPastStartDate);
  const showReceipt = (!partnerCode || partnerConfig.receipt) && showTransactions;
  const allowNotes = (!partnerCode || partnerConfig.notes) && !(COMPLETED || CLOSED || isPastStartDate);
  const showNonGuaranteed = !COMPLETED && !CLOSED && !jobs.every((job) => job.guaranteed);
  const isLongDistance = longDistance;
  const deltaBillingEnabled = deltaEnabled && postMove;
  const qrActive = deltaEnabled && !postMove;
  const meetTeamEnabled = isWithinDates(jobs?.[0]?.start, 3, '[]') || isMoveDay(jobs?.[0]?.start) || postMove;

  // Only allow non LD, Atlanta, full service moves
  const paymentDeferralAllowed =
    !longDistance && locations?.[0].marketCode === 'ATLANTA_GA' && serviceJobs?.[0]?.service.id === 'MOVING';

  return {
    allowTip,
    showTransactions,
    allowReschedule,
    allowUpdatePaymentMethod,
    allowManageAccount,
    allowCancel,
    allowModify,
    showReceipt,
    allowNotes,
    showNonGuaranteed,
    isLongDistance,
    deltaEnabled,
    deltaBillingEnabled,
    qrActive,
    postMove,
    meetTeamEnabled,
    paymentDeferralAllowed,
  };
}
