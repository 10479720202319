export function identify(customerInfo) {
  if (window?.analytics?.identify) {
    window.analytics.identify(customerInfo);
  }
}

export function track(title, dataObject, ctx = {}) {
  // Add hard exit for non client side
  if (typeof window === 'undefined') {
    return;
  }
  if (window?.analytics?.track) {
    let id;
    try {
      id = window.localStorage.getItem('clickid');
    } catch {
      id = null;
    }

    const context = {
      context: {
        ...ctx,
        ...(id && {
          referrer: {
            type: 'impactRadius',
            id,
          },
        }),
      },
    };

    window.analytics.track(title, dataObject, context);
  }
}
