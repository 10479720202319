import serviceQuotesFragment from './serviceQuotesFragment';

const packageQuoteFragment = `
  id
  available
  customizable
  duration
  pricingStrategy
  longDistance
  locations {
    id
    address {
      line1
      line2
      city
      country
      region
      postalCode
    }
    propertyInfo {
      type
      rooms
      area
      garage
      attic
      basement
      stories
      metadata {
        key
        value
      }
      floor
    }
  }
  package {
    id
    name
    group_string
  }
  pricing {
    amount
    currency
  }
  serviceQuotes {
    ${serviceQuotesFragment}
  }
  feeQuotes @include(if: ${process.env.NEXT_PUBLIC_FF_BASE_FEE === 'true'}) {
    id
    feeName
    pricingStrategy
    pricing {
      amount
      currency
    }
  }
`;

export default packageQuoteFragment;
