export default function getServiceJobs(reservation) {
  const isMovingService =
    reservation?.jobs?.some((job) => job.service.id === 'TRANSIT') &&
    reservation?.jobs?.some((job) => job.service.id === 'LOADINGUNLOADING');

  if (isMovingService) {
    // If reservation has both TRANSIT and LOADINGUNLOADING - we need faux moving service displayed
    const movingServiceJobs = reservation?.jobs?.filter(
      (job) => job.service.id === 'TRANSIT' || job.service.id === 'LOADINGUNLOADING',
    );

    const allFixed = movingServiceJobs?.every((job) => job.payoutStrategy === 'FIXED');
    const allHourly = movingServiceJobs?.every((job) => job.payoutStrategy === 'HOURLY');

    if (!allHourly && !allFixed) {
      // Unexpected mix of fixed/hourly services - display jobs as returned by API - no combo job
      return reservation?.jobs?.sort((a, b) => a.start - b.start);
    }

    const sumPrice = movingServiceJobs?.reduce((acc, job) => acc + (job.pricing?.amount ?? 0), 0);

    const moveJob = {
      ...movingServiceJobs[0],
      completed: movingServiceJobs?.every((job) => job.completed),
      customerReviewed: movingServiceJobs?.every((job) => job.customerReviewed),
      ids: movingServiceJobs?.map((job) => job.id),
      service: {
        ...movingServiceJobs?.[0]?.service,
        id: 'MOVING',
        name: 'Moving',
        guaranteed: movingServiceJobs?.every((job) => job.service.guaranteed),
      },
      slots: movingServiceJobs?.reduce((acc, job) => {
        acc.push(...job.slots);
        return acc;
      }, []),
      pricing: {
        amount: sumPrice,
        currency: 'USD',
      },
    };
    return [
      moveJob,
      ...(reservation?.jobs?.filter((job) => job.service.id !== 'TRANSIT' && job.service.id !== 'LOADINGUNLOADING') ?? []),
    ]; // Adds any additional jobs that are not moving services
  }

  return reservation?.jobs?.sort((a, b) => a.start - b.start); // Otherwise, always return all services
}
