import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material';
import WifiCalling3Icon from '@mui/icons-material/WifiCalling3';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ReservationContext from '../../providers/ReservationContext';

const Footer = () => {
  const router = useRouter();
  const { id } = useContext(ReservationContext);
  const navOptions = {
    home: '/',
    manage: '/manage',
    account: '/account',
    contact: '/contact',
  };
  const [value, setValue] = useState(router.pathname);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    router.push(newValue);
  };

  useEffect(() => {
    // If current path doesn't include the current nav option, find the actual one and set it.
    if (!router.pathname.includes(value)) {
      const currentNavOption = Object.keys(navOptions).find((key) => router.pathname.includes(navOptions[key]));
      setValue(navOptions[currentNavOption]);
    }
  }, [router?.pathname]);

  return (
    <Box displayPrint='none'>
      <Paper
        sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, display: { xs: id ? 'block' : 'none', md: 'none' } }}
        elevation={3}
      >
        <BottomNavigation value={value} onChange={handleChange}>
          <BottomNavigationAction label='•' value={navOptions.home} icon={<HomeOutlinedIcon />} />
          <BottomNavigationAction label='•' value={navOptions.manage} icon={<SettingsOutlinedIcon />} />
          <BottomNavigationAction label='•' value={navOptions.account} icon={<AccountCircleOutlinedIcon />} />
          <BottomNavigationAction label='•' value={navOptions.contact} icon={<WifiCalling3Icon />} />
        </BottomNavigation>
      </Paper>
    </Box>
  );
};

export default Footer;
