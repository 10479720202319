import {
  TRANSACTION_PAYMENT_STATUS_API,
  TRANSACTION_STATUS_API,
  TRANSACTIONS_STATUS_UI,
  TRANSACTION_TYPE,
} from '../../../constants/transactions';

const getDisplayTxnStatus = ({ transactionPaymentStatusApi = null, transactionStatusApi, transactionTypeApi }) => {
  const isRefund = transactionTypeApi.toLowerCase() === TRANSACTION_TYPE.REFUND;
  const combinedTxnAndPaymentStatus = transactionPaymentStatusApi
    ? `${transactionStatusApi}_${transactionPaymentStatusApi}`.toLowerCase()
    : transactionStatusApi.toLowerCase();

  let transactionStatusUi;

  /**
   * TODO (mprew97, https://bellhopmoving.atlassian.net/browse/WEB-442): would like to go through this with jess to see if we can clean it up
   * or make it more true to source. Feel like its accurate since its been tested in two apps
   * but in order to cover funky edge cases and make this more legible, think it needs some rework.
   */
  switch (combinedTxnAndPaymentStatus) {
    case TRANSACTION_STATUS_API.COMPLETED:
    case `${TRANSACTION_STATUS_API.COMPLETED}_${TRANSACTION_PAYMENT_STATUS_API.CAPTURED}`:
    case `${TRANSACTION_STATUS_API.COMPLETED}_${TRANSACTION_PAYMENT_STATUS_API.PARTIALLY_CAPTURED}`:
    case `${TRANSACTION_STATUS_API.COMPLETED}_${TRANSACTION_PAYMENT_STATUS_API.PARTIALLY_REFUNDED}`:
      transactionStatusUi = isRefund ? TRANSACTIONS_STATUS_UI.ISSUED : TRANSACTIONS_STATUS_UI.PAID;
      break;
    case `${TRANSACTION_STATUS_API.COMPLETED}_${TRANSACTION_PAYMENT_STATUS_API.REFUNDED}`:
      transactionStatusUi = isRefund ? TRANSACTIONS_STATUS_UI.ISSUED : TRANSACTIONS_STATUS_UI.PAID;
      break;

    case TRANSACTION_STATUS_API.CANCELED:
      transactionStatusUi = TRANSACTIONS_STATUS_UI.CANCELED;
      break;

    case TRANSACTION_STATUS_API.FAILED:
    case `${TRANSACTION_STATUS_API.FAILED}_${TRANSACTION_PAYMENT_STATUS_API.AUTHORIZED}`:
      transactionStatusUi = TRANSACTIONS_STATUS_UI.DECLINED;
      break;

    case TRANSACTION_STATUS_API.OPEN:
    case `${TRANSACTION_STATUS_API.COMPLETED}_${TRANSACTION_PAYMENT_STATUS_API.AUTHORIZED}`:
    case TRANSACTION_STATUS_API.ON_HOLD:
    case `${TRANSACTION_STATUS_API.ON_HOLD}_${TRANSACTION_PAYMENT_STATUS_API.AUTHORIZED}`:
      transactionStatusUi = isRefund ? TRANSACTIONS_STATUS_UI.PENDING : TRANSACTIONS_STATUS_UI.DUE;
      break;

    default:
      transactionStatusUi = TRANSACTIONS_STATUS_UI.DUE;
  }

  return transactionStatusUi;
};

export default getDisplayTxnStatus;
