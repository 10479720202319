import React from 'react';
import { signOut } from 'next-auth/client';
import { Box, Typography } from '@mui/material';
import Link from '../components/Link';
import PHONE_NUMBERS from '../constants/phoneNumbers';

const Custom500 = () => (
  <Box
    textAlign={{ sm: 'center' }}
    maxWidth={600}
    display='flex'
    flexDirection='column'
    justifyContent='center'
    alignContent='center'
    minHeight='80vh'
    m={{ xs: 4, sm: '0 auto' }}
  >
    <Typography variant='h1'>
      <span role='img' aria-label='man shrugging'>
        🤷‍♂️
      </span>
    </Typography>
    <Typography variant='h2'>Something went wrong.</Typography>
    <Typography variant='body1' color='textSecondary'>
      It&lsquo;s us, not you.
    </Typography>
    <Typography
      variant='body1'
      onClick={signOut}
      color='primary'
      sx={{
        cursor: 'pointer',
      }}
    >
      Let&lsquo;s get you home
    </Typography>
    <Typography variant='body1' color='textSecondary'>
      Or give us a call, <Link href={`tel:${PHONE_NUMBERS.CUSTOMER_SERVICE}`}>{PHONE_NUMBERS.CUSTOMER_SERVICE}</Link>
    </Typography>
  </Box>
);

export async function getStaticProps() {
  return {
    props: {},
  };
}

export default Custom500;
