import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const VerticalDivider = styled(Box)(({ theme }) => ({
  width: 1,
  height: 100,
  backgroundColor: theme.palette.neutral.light,
  borderRadius: 3,
}));

export default VerticalDivider;
