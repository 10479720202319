import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';

/**
 * isWithinDates
 * @param {int} startTime: unix timestamp
 * @param {int} dateRange: number for endOfDateRange
 * @param {string} option: one of "(), [], [)"
 * @returns boolean
 */
const isWithinDates = (serviceStartTime, dateRange, option = '[]') => {
  dayjs.extend(isBetween);
  const currentDate = dayjs();
  const lastDateInRange = dayjs.unix(serviceStartTime);
  const startDateInRange = dayjs(lastDateInRange).subtract(dateRange, 'day');
  /**
   * From DAYJS Docs (isBetween) https://day.js.org/docs/en/plugin/is-between:
   *
   * Parameter 4 is a string with two characters; '[' means inclusive, '(' exclusive
   * '()' excludes start and end date (default)
   * '[]' includes start and end date
   * '[)' includes the start date but excludes the stop
   */

  return currentDate.isBetween(startDateInRange, lastDateInRange, null, option);
};

export const isMoveDay = (serviceStartTime) => {
  dayjs.extend(isToday);
  const orderStartDate = dayjs.unix(serviceStartTime);
  return dayjs().isBetween(orderStartDate.startOf('day'), orderStartDate.endOf('day'));
};

export default isWithinDates;
