import { palette } from '../../global';

export default {
  styleOverrides: {
    firstButton: {
      borderRadius: '50%',
    },
    middleButton: {
      borderRadius: '50%',
      margin: 0,
      border: '2px solid',
      borderColor: palette.neutral.light,
    },
    lastButton: {
      borderRadius: '50%',
      margin: 0,
      border: '2px solid',
      borderColor: palette.neutral.light,
    },
  },
};
