import { typography } from '../../global';

export default {
  styleOverrides: {
    root: {
      fontFamily: typography.h1.fontFamily,
      fontSize: typography.body1.fontSize,
    },
  },
};
