import dayjs from 'dayjs';

import RESERVATION_STATUS from '../../constants/reservationStatus';

const getReservationTimeline = ({ jobs, status }) => {
  let timeline = 'Prep';

  const startTime = jobs?.[0]?.start;
  if (!startTime) {
    return timeline;
  }

  const reservationStartDate = dayjs.unix(startTime);

  if (status === RESERVATION_STATUS.COMPLETED) {
    timeline = 'Post';
  } else if (reservationStartDate.isToday()) {
    timeline = 'Move';
  }

  return timeline;
};

export default getReservationTimeline;
