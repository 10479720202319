import React, { useContext } from 'react';
import { bool, func } from 'prop-types';
import { Box, Drawer as MuiDrawer, ListItemText, ListItemButton, MenuList, Divider } from '@mui/material';
import Link from '../../Link';
import ReservationContext from '../../../providers/ReservationContext';
import getLinks from './links';

const Drawer = ({ drawerOpen, setDrawerOpen }) => {
  const { permissions } = useContext(ReservationContext);
  const links = getLinks({ permissions });
  return (
    <MuiDrawer open={drawerOpen} anchor='right' onClose={() => setDrawerOpen(false)}>
      <Box width={{ xs: '100vw', md: 400 }} borderTop={1} borderColor='neutral.lighter' px={5} mt={10}>
        <MenuList sx={{ marginTop: 3 }}>
          {links.map(({ href, external, callback, title, Icon }, i) => (
            <div>
              <ListItemButton
                sx={{
                  py: { xs: 1.25, md: 2 },
                  ml: { xs: -2, md: -4 },
                }}
                component={Link}
                href={href}
                external={external}
                onClick={() => {
                  if (callback) callback();
                  setDrawerOpen(false);
                }}
              >
                <Icon />
                <Box pl={2}>
                  <ListItemText
                    primary={title}
                    primaryTypographyProps={{
                      variant: 'h5',
                    }}
                  />
                </Box>
              </ListItemButton>
              {i === 4 && (
                <Box
                  sx={{
                    py: { xs: 1, md: 2 },
                  }}
                >
                  <Divider component='li' />
                </Box>
              )}
            </div>
          ))}
        </MenuList>
      </Box>
    </MuiDrawer>
  );
};

Drawer.propTypes = {
  drawerOpen: bool.isRequired,
  setDrawerOpen: func.isRequired,
};

export default Drawer;
