import { getSession } from 'next-auth/client';
import fetchAPI from '../fetchAPI';

export default async () => {
  const session = await getSession();
  if (session?.user?.getReservationToken?.jwt) {
    const contractStateQuery = `
      query contracts {
        contractState {
          contract_type
          contract_pdf_url
          contract_created_at
          contract_approved
          contract_approved_at
        }
      }
    `;

    const data = await fetchAPI({
      query: contractStateQuery,
      headers: {
        Authorization: `Bearer ${session.user.getReservationToken.jwt}`,
      },
    });

    return data?.contractState;
  }
  return {};
};
