const serviceQuotesFragment = `
  id
  available
  guaranteed
  pricingStrategy
  duration
  minimumBillableDurationHours
  service {
    id
    name
  }
  pricing {
    amount
    currency
  }
  locations {
    id
    address {
      line1
      line2
      city
      country
      region
      postalCode
    }
    propertyInfo {
      type
      rooms
      area
      garage
      attic
      basement
      stories
      metadata {
        key
        value
      }
      floor
    }
  }
  workers
  locations {
    id
    address {
      line1
      line2
      city
      region
      country
      postalCode
    }
    propertyInfo {
      type
      rooms
      area
      garage
      attic
      basement
      stories
      metadata {
        key
        value
      }
      floor
    }
  }
  marketplace
`;

export default serviceQuotesFragment;
